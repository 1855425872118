<template>
  <div>
    <b-row>
      <div class="col-8">
        <b-card no-body>
          <b-overlay :show="isLoading" opacity="0.70" variant="transparent" rounded="sm">
            <b-card-header
                class="d-flex justify-content-between align-items-center pt-52 pb-75"
                v-if="muralShow && muralShow.user"
              >
                <h5>
                  <b-avatar
                    :src="muralShow.user.photo"
                    size="50"
                    class="mr-1"
                  />
                    {{muralShow.user.name}}
                  </h5>
                <small class="text-muted">Data de publicação: <b> 
                {{muralShow.created_at | dateFormat}}
                </b></small>
            </b-card-header>
            <b-card-body>
              <div>
                <b>
                  {{muralShow.name}}
                </b>
                <p class="text-justify">
                  <b-card-text v-html="muralShow.description" />
                </p>
                <div v-if="muralShow.activity_student != null">
                  <b>
                    Atividade
                  </b>
                  <p class="text-justify">
                    {{muralShow.activity_student.response}}
                  </p>
                  <p>
                    <i><b>Aluno: </b> {{muralShow.activity_student.student.name}}</i>
                  </p>
                </div>
              </div>
            </b-card-body>
          </b-overlay>
        </b-card>
        <b-card title="Compartilhar" v-if="$can('read', 'dashboard.teacher')  && !$can('manage', 'all')">
          <div class="comparilhar-style">
            <b-row>
              <div class="col-2">
                <b-button
                  variant="relief-primary"
                  block
                  size="sm"
                  @click="generateLink"
                >
                  Gerar Link
                </b-button>
              </div>
              <div class="col-md">
                <b-input-group>
                  <b-form-input disabled placeholder="Crie um link compartilhavel para esta postagem" size="sm" :value="linkPublic"/>
                  <b-input-group-append>
                    <b-button variant="outline-primary" size="sm" v-clipboard:copy="linkPublic" v-clipboard:success="onCopy" v-clipboard:error="onError">
                      <feather-icon icon="CopyIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md">
        <b-card title="Comentários">
          <b-overlay :show="isLoadingComments" opacity="0.70" variant="transparent" rounded="sm">
            <div class="comments-public-style overflow-auto">
              <div v-for="(tr, indextr) in muralShow.comments" :key="indextr">
                <div
                  class="d-flex justify-content-start align-items-center mb-1"
                >
                  <b-avatar
                    :src="tr.user.photo"
                    size="50"
                    class="mr-1"
                  />
                  <div class="profile-user-info">
                    <h6 class="mb-0">
                     {{tr.user.name}}<small class="text-muted">| {{tr.created_at | dateFormat}}</small>
                    </h6>
                    <div class="text-justify">
                      <small>{{tr.description}}</small>
                      <div class="text-link" v-if="$can('read', 'dashboard.teacher')  && !$can('manage', 'all') || user.id == tr.user.id">
                        <b-link @click="saveComment(tr.id, tr.description)" v-b-modal.modal-update>Editar</b-link> | <b-link @click="deleteCommentMural(tr.id)"> Apagar</b-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <b-modal
                id="modal-update"
                ref="modal-update"
                centered
                title="Atualizar comentário"
                ok-only
                ok-title="Accept"
              >
                <b-card-text>
                  <b-form-input 
                    placeholder="Comentário"
                    v-model="updateComment.description"
                  />
                </b-card-text>
                <template #modal-footer>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class=""
                      variant="outline-primary"
                      block
                      @click="updateCommentMural()"
                    >
                      Alterar comentário
                    </b-button>
                  </b-col>
                </template>
              </b-modal>
            </div>
          </b-overlay>
          <div
            class="d-flex justify-content-start align-items-center"
          >
            <b-avatar
              :src="user.photo"
              size="50"
              class="mr-1"
            />
            <b-input-group>
              <b-form-input placeholder="Escreva um comentario" size="sm" v-model="addComment.description" v-on:keyup.enter="addCommentMural()"/>
              <b-input-group-append>
                <b-button variant="outline-primary" size="sm" @click="addCommentMural()">
                  <feather-icon icon="SendIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-card>
      </div>
    </b-row>
  </div>
</template>
<script>
/* eslint-disable */
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Clipboard from '@/libs/clipboard'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    Clipboard
  },
  directives: {
    Ripple
  },
  created(){
    this.fetchDetail()
  },
  computed: {
    ...mapState("murals", ["muralShow", "linkPublic"]),
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    }
  },
  data() {
    return {
      isLoading: false,
      isLoadingComments: false,
      updateMode: false,
      addComment: {
        description: null,
      },
      deleteComment: {
        mural_id: null,
        comments_id: null,
      },
      updateComment: {
        mural_id: null,
        comments_id: null,
        description: null,
      },

      link: null
    
    };
  },
   methods: {
    ...mapActions("murals", ["addComments", "deleteComments", "updateComments", "storeLink"]),

    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Link copiado com sucesso',
          icon: 'CopyIcon',
          variant: 'success'
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Falha ao copiar o link!',
          icon: 'XIcon',
          variant: 'danger'
        },
      })
    },

    generateLink(){
      this.storeLink({mural_id: this.$route.params.mural_id})
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Link gerado com sucesso',
            icon: 'MessageSquareIcon',
            variant: 'success'
          }
        })
      })
      .finally(() => {
      })
    },

    addCommentMural(){
      this.isLoadingComments = true
      this.addComments({
        ...this.addComment,
        mural_id: this.$route.params.mural_id,
      })
      .then(() => {
        this.isLoadingComments = false
        this.addComment.description = null
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Enviado com successo',
            icon: 'MessageSquareIcon',
            variant: 'success'
          }
        })
      })
      .finally(() => {
        this.addComment.description = null
        this.isLoadingComments = false
      })
    },

    deleteCommentMural(id){
      this.isLoadingComments = true
      this.deleteComment.mural_id = this.$route.params.mural_id,
      this.deleteComment.comments_id = id,
      this.deleteComments({
        ...this.deleteComment
      })
      .then(() => {
        this.isLoadingComments = false
        this.deleteComment.mural_id = null,
        this.deleteComment.comments_id = null,
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Enviado com successo',
            icon: 'MessageSquareIcon',
            variant: 'success'
          }
        })
      })
      .finally(() => {
        this.deleteComment.mural_id = null,
        this.deleteComment.comments_id = null,
        this.isLoadingComments = false
      })
    },

    saveComment(id, description){
      this.updateComment.comments_id = id
      this.updateComment.description = description
    },

    updateCommentMural(id){
      this.isLoadingComments = true
      this.updateComment.mural_id = this.$route.params.mural_id,
      this.updateComments({
        ...this.updateComment
      })
      .then(() => {
        this.isLoadingComments = false
        this.updateComment.mural_id = null,
        this.updateComment.comments_id = null,
        this.updateComment.description = null,
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Enviado com successo',
            icon: 'MessageSquareIcon',
            variant: 'success'
          }
        })
        this.$bvModal.hide('modal-update')
      })
      .finally(() => {
        this.updateComment.mural_id = null,
        this.updateComment.comments_id = null,
        this.updateComment.description = null,
        this.isLoadingComments = false
      })
    },

    fetchDetail(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("murals/fetchDetails")
        .then((response) => {
          this.isLoading = false
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.comments-style {
  width: 100%;
  height: 40rem;
}
.comments-public-style{
  width: 100%;
  height: 40rem;
}

.comparilhar-style {
  width: 100%;
  height: 1.3rem;
}
.murals-public-style{
  width: 100%;
  height: 30rem;
}


/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}

.text-link{
  color: rgb(156, 156, 156);
  font-size: 0.9rem;
}
</style>